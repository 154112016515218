$(document).ready(function() {
  ("use strict");

  // -----------------
  // max-width: 639px
  // -----------------

  var mediaQuery = window.matchMedia("(max-width: 639px)");

  mediaQuery.addListener(max639);

  function max639(mediaQuery) {
    if (mediaQuery.matches) {
      // Mobile menu
      // -----------
      // crear el botó del menú
      // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
      // ocultar el menú
      $(".menu-wrapper")
        .attr("hidden", "")
        .addClass("js-hidden");
    } else {
      // ≥ 640px

      // Mobile menu
      // -----------
      // treure el botó
      // $('.main-nav button').remove();
      // mostrar el menú
      $(".menu-wrapper")
        .removeAttr("hidden")
        .removeClass("js-hidden");
    }
  }

  // clicar el botó
  $(".menu-button").on("click", function() {
    var headerHeight = $(".site-logo").outerHeight(true);
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("body").toggleClass("js-menu-open");
    // canviar atributs del botó i del menú
    if ($(this).attr("aria-expanded") == "true") {
      $(this).attr("aria-expanded", "false");
      $(".menu-wrapper")
        .attr("hidden", "")
        .addClass("js-hidden");
    } else {
      $(this).attr("aria-expanded", "true");
      $(".menu-wrapper")
        .removeAttr("hidden")
        .removeClass("js-hidden")
        .css("margin-top", headerHeight);
    }
  });

  max639(mediaQuery);

  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

  // ----------
  // sticky nav
  // ----------
  if ($(".site-menu").length) {
    var fixmeTop = $(".site-menu").offset().top; // get initial position of the element

    $(window).scroll(function() {
      // assign scroll event listener

      var currentScroll = $(window).scrollTop(); // get current position

      var offset = 0;
      if ($('.theme-cpnl').length) {
    	  offset = 26;
      }
      if (currentScroll >= fixmeTop + offset) {
        $(".nav").addClass("nav--sticky");
        $("body").addClass("sticky-nav");
      } else {
        $(".nav").removeClass("nav--sticky");
        $("body").removeClass("sticky-nav");
      }
    });
  }

  // ----------
  // scroll top
  // ----------

  $(window).scroll(function() {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").fadeIn();
    } else {
      $(".scrolltop").fadeOut();
    }
  });
  $('a[name]').addClass('anchor_offset');


  // ----------
  // cercador gramàtica
  // ----------

  $("#showAdvancedSearch").click(function(){
    $("#advance-search-wrapper").css("display", "block");
    $("#cercadorButton").css("display", "none");
  });
  $(".close-advanced").click(function(){
    //$("#advance-search-wrapper").css("display", "none");
    //$("#cercadorButton").css("display", "block");
    e.preventDefault();
  	$("#advance-search-wrapper").fadeOut();
  	$("#cercadorButton").fadeIn();
  	return false;
  });


  // ------------
  // appendAround
  // ------------
  //  $(".submenu").appendAround();
  //  $(".selector-cnl").appendAround();

  // -------
  // Toggles
  // -------
  function collapse(toggle) {
    var collapsibleBox = document.getElementById(
      toggle.getAttribute("data-a11y-toggle")
    );
    collapsibleBox.setAttribute("aria-hidden", true);
    toggle.setAttribute("aria-expanded", false);
  }

  function collapseAll(event) {
    toggles
      .filter(function(t) {
        return t !== event.target;
      })
      .forEach(collapse);
  }

  var toggles = Array.prototype.slice.call(
    document.querySelectorAll(".connected-toggles [data-a11y-toggle]")
  );

  toggles.forEach(function(toggle) {
    toggle.addEventListener("click", collapseAll);
  });

  //amagar toggles desplegats al clicar fora
  $("body").click(function(e) {
    var allyAttribute = $(e.target).attr("data-a11y-toggle");
    if (
      (typeof allyAttribute === typeof undefined || allyAttribute === false) &&
      $(e.target).closest("#searchForm").length == 0
    ) {
      $("#cnlList, #xxssList, #searchForm, #cnlList-L").attr("aria-hidden", "true");
    }
  });

  $(".tools__btn")
    .next()
    .removeAttr("hidden");

  // -------
  // Tootlip
  // -------
  $(".cal__tooltip").attr("hidden", "");
  // $(".cal tbody a").on("mouseover", function() {
  //   if ($(this).attr("aria-expanded") == "true") {
  //     $(this).attr("aria-expanded", "false");
  //     //   .removeClass("tools__btn--open");
  //     $(this)
  //       .next()
  //       .attr("hidden", "");
  //   } else {
  //     $(this).attr("aria-expanded", "true");
  //     //   .addClass("tools__btn--open");
  //     $(this)
  //       .next()
  //       .removeAttr("hidden");
  //   }
  // });

  // -----------
  // Collapsible (https://inclusive-components.design/collapsible-sections/)
  // -----------

  if ($(".collapsible")[0]) {
    (function() {
      var headings = document.querySelectorAll(".collapsible__title");
      Array.prototype.forEach.call(headings, function(h) {
        var btn = h.querySelector("button");
        var target = h.nextElementSibling;

        btn.onclick = function() {
          var expanded = btn.getAttribute("aria-expanded") === "true";
          btn.setAttribute("aria-expanded", !expanded);
          target.hidden = expanded;
        };
      });
    })();
  }


  $("#showAdvancedSearch").click(function(e){
  	e.preventDefault();
  	$(this.hash).fadeIn();
  	$(this).fadeOut();
  	return false;
  });


  // ------------------------
  // Detect objectFit support (https://codepen.io/pawelgrzybek/pen/Rrybqg)
  // ------------------------
  if ("objectFit" in document.documentElement.style === false) {
    // assign HTMLCollection with parents of images with objectFit to variable
    var container = document.getElementsByClassName("top-img-container");

    // Loop through HTMLCollection
    for (var i = 0; i < container.length; i++) {
      // Asign image source to variable
      var imageSource = container[i].querySelector("img").src;

      // Hide image
      container[i].querySelector("img").style.display = "none";

      // Add background-size: cover
      container[i].style.backgroundSize = "cover";

      // Add background-image: and put image source here
      container[i].style.backgroundImage = "url(" + imageSource + ")";

      // Add background-position: center center
      container[i].style.backgroundPosition = "center center";
    }
  } else {
    // You don't have to worry
    // console.log("No worries, your browser supports objectFit");
  }

  // ------
  // Slider
  // ------

  $(".slider").slick({
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000
  });
  $(".slider-cpnl").slick({
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000
  });
  $(".slider-galeria").slick({
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  // ------------
  // Range slider (https://codepen.io/smhigley/pen/ObWbdy)
  // ------------
  if ($(".slider-wrapper").length) {
    var slider = document.querySelector(".slider-wrapper"),
      sliderInput = slider.querySelector("input"),
      sliderOutput = slider.querySelector("output"),
      sliderThumb = slider.querySelector(".custom-thumb"),
      sliderFill = slider.querySelector(".custom-fill");

    function initSlider(min, max, startValue) {
      sliderInput.setAttribute("min", min);
      sliderInput.setAttribute("max", max);
      sliderInput.value = startValue;

      var onSliderChange = function onSliderChange(event) {
        var value = event.target.value;
        sliderOutput.innerHTML = value + " Km.";
        sliderThumb.style.left = (value / max) * 100 + "%";
        sliderFill.style.width = (value / max) * 100 + "%";
      };

      sliderInput.addEventListener("input", onSliderChange);
      sliderInput.addEventListener("change", onSliderChange); // set slider to initial value

      var initialInput = new Event("input", {
        target: {
          value: startValue
        }
      });
      sliderInput.dispatchEvent(initialInput);
    }

    if ($(".slider-wrapper input[type='range']").length) {
    	var sliderMinValue =  $(".slider-wrapper input[type='range']").attr('min');
    	var sliderMaxValue =  $(".slider-wrapper input[type='range']").attr('max');
    	var sliderValue =  $(".slider-wrapper input[type='range']").val();
    	initSlider(sliderMinValue, sliderMaxValue, sliderValue);
    }
  }

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----

  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function(index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function(tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function(e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function(e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function(panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }
});

// ----------
// MFP Dialog
// ----------

$(".dialog-popup").magnificPopup({
  type: "inline",
  midClick: true
  //  showCloseBtn: false
});


if($('.infinite-paginator-button-wrapper').length) {
  $(document).on('click', '.infinite-paginator-button-wrapper a', function(e){


    $(this).closest('.infinite-paginator-button-wrapper').remove();

    var listURL = $(this).attr('href');
    $.ajax(
    {
      url : listURL,
      type: "GET",
      success:function(data, textStatus, jqXHR)
      {
        $('.infinite-paginator-content-wrapper').append(data);
        $('a:external, a[rel=external]').attr('target', '_blank').addClass('external').attr('rel', 'external');
        $('a:download, a[rel=document]').attr('target', '_blank').addClass('document').attr('rel', 'document');
      },
      error: function(jqXHR, textStatus, errorThrown)
      {

      }
    });
    e.preventDefault(); //STOP default action
  });
}

$("#plantillaDatatable table").addClass("dataTable");
//$("#plantillaEditoraBeneficis table").addClass("dataTable");



