$(document).ready(function(){
	$('table.dataTable').each(function( index ) {
        var paginate = true;
        if ($(this).find('tr').length <= 10) {
            paginate = false;
        }
        var scroll = false;

        if ($(this).data('scroll')) {
            scroll = true;
            //$(this).find('th, tr').css('white-space', 'nowrap');
        }
        $(this).DataTable({
            bPaginate: paginate,
            bInfo: paginate,
            bStateSave: true,
            scrollX: scroll,
            autoWidth: false,
            dom:
                "<'row'<'medium-6 columns'i><'medium-6 columns'f>>" +
                "<'row'<'medium-12 columns'tr>>" +
                "<'row'<'medium-5 columns'l><'medium-7 columns'p>>",
            stateSave: true,
            stateDuration: 60 * 60,
            language: {
                "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
                "zeroRecords": "No s\'ha trobat cap entrada",
                "info": "Mostrant pàgina _PAGE_ de _PAGES_",
                "infoEmpty": "Cap entrada disponible",
                "infoFiltered": "(de _MAX_ entrades totals)",
                "search": "Cerca:",
                "paginate": {
                    "first": "Primera",
                    "last": "Última",
                    "next": '<span class="vh">Següent</span>',
                    "previous": '<span class="vh">Anterior</span>'
                }
            },
            initComplete: function () {
                var table = this;
                this.api().columns().every(function (i) {
                    var column = this;
                    var titol = '';
                    if ($(column.header()).data('select-search') == true) {
                        titol = column.header();
                        info = column.data().map(function(obj){
                            try{
                                if (typeof obj == 'string' && $(obj).text()) {
                                    return $(obj).text();
                                } else if (typeof obj != 'string' && $(obj).text()) {
                                    return $(obj).text();
                                } else {
                                    return obj;
                                }
                            }catch(err){
                                return obj;
                            }
                        });

                        var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
                        info = info.unique().sort(collator.compare);

                        if (info.length > 1) {
                            var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
                            search.append('<label>' + $(titol).html() + ':</label>');
                            var select = $('<select class="form-control input-sm"><option value=""></option></select>')
                                .appendTo( search )
                                .on( 'change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex(
                                        removeAccents($(this).val())
                                    );
                                    column
                                        .search( val ? '^'+val+'$' : '', true, false )
                                        .draw();
                                } );
                            var selected = false;
                            info.each( function ( d, j ) {
                                var current = column.search();
                                //TODO: quant no hi ha html falla al map
                                //TODO: no manté el select al canviar de pàgina
                                if (d == '') {
                                    return;
                                }
                                if (current != '' && current == '^'+d+'$') {
                                    select.append( '<option selected value="'+d+'">'+d+'</option>' );
                                    selected = true;
                                } else {
                                    select.append( '<option value="'+d+'">'+d+'</option>' );
                                }
                            } );
                            if (selected == false) {
                                //si opció seleccionada no està al llistat, desfer cerca
                                column
                                    .search('', true, false )
                                    .draw();
                            }
                        } else {
                            //si no hi ha valors pel select, desfer cerca
                            column
                                .search('', true, false )
                                .draw();

                        }
                    }
                } );

                var sortSelect = this.data('sortselect');
                if (sortSelect && sortSelect.length > 0) {
                    var search = this.closest('.dataTables_wrapper').find('.dataTables_filter');
                    search.append('<label>Ordenació:</label>');
                    var select = $('<select class="form-control input-sm sorter"></select>')
                        .appendTo( search )
                        .on( 'change', function () {
                            var val = $(this).val();
                            val = val.split('_');
                            table.api()
                                .order([val])
                                .draw();
                        } );

                    var currentOrder = table.api().order();
                    sortSelect.forEach( function ( d, j ) {
                        if (d.column == currentOrder[0][0]) {
                            select.append( '<option selected data-column="'+d.column+'" value="'+d.column+'_'+d.direction+'">'+d.title+'</option>' );
                        } else {
                            select.append( '<option data-column="'+d.column+'" value="'+d.column+'_'+d.direction+'">'+d.title+'</option>' );
                    }
                    } );
                }
            },
            "drawCallback": function( settings ) {
                var currentOrder = this.api().order();
                var sorter = this.closest('.dataTables_wrapper').find('.sorter');
                if (sorter.length > 0) {
                    sorter.find('option[data-column='+currentOrder[0][0]+']').prop('selected', true);
                }
            }
        });
    });
	
	$('form.cercador-jocs select, form.cercador-jocs input:checkbox').on('change',  function(){
		var form = $(this).closest('form');
		form.addClass('form-submitted');
		form.after('<div class="lds-dual-ring"></div>');
		form.submit();
	});
	
	
	
	/**
	 * Filtre i cercador de poblacions
	 */
    updateLettersList = function(){
    	$("ol.abc-list>li").each(function(){
        	var letterContainer = $(this);
        	if (letterContainer.find('ol li.hide').length == letterContainer.find('ol li').length) {
        		letterContainer.fadeOut();
        	} else {
        		letterContainer.fadeIn();
        	}
    	});
    }
    
    $('#filtrePoblacio').on('keyup', function(){
        var value = $(this).val().toLowerCase().trim();
        if (value == ''){
        	$("ol.abc-list li li").removeClass('hide');
        } else {
        	$("ol.abc-list li li:not([data-filter*='" + value +"'])").addClass('hide');
        	$("ol.abc-list li li[data-filter*='" + value +"']").removeClass('hide');
        }
        updateLettersList()
    });	
    
    /*
     * Cercador de cursos 
     */
    $('select.select2-deselect').select2({
       allowClear: true
    });
    
    //  form sé quin nivell tinc
    if ($('.troba-curs-form--cursos').length) {
    	$('#nivell').on('change', function (){
    		var nivell = $(this).find('option:selected').data('nivell');
    		$("div[data-nivell]").hide();
    		$("div[data-nivell='" + nivell + "']").fadeIn();
    		$(this).closest('form').find('radio').removeProp('checked').removeAttr('checked');
    	});
    }
    
    $('#filtre-cursos input, #filtre-cursos select').on('change', function(){
    	$('#filtre-cursos').addClass('form-submitted');
    	$('#filtre-cursos').after('<div class="lds-dual-ring"></div>');
    	$('#filtre-cursos').submit();
    }) 
    
    $('#filtre-blocs input, #filtre-blocs select').on('change', function(){
    	$('#filtre-blocs').addClass('form-submitted');
    	$('#filtre-blocs').after('<div class="lds-dual-ring"></div>');
    	$('#filtre-blocs').submit();
    })
    
    
    /*
     * Pisos
     */
    
    $('.section-divider .section--bg-logos').each(function(){
    	$(this).closest('.section-divider').addClass('white');
    });
    
    
    $('.chronology--years').slick({
    	  dots: false,
    	  infinite: false,
    	  speed: 300,
    	  slidesToShow: 8,
    	  slidesToScroll: 8,
    	  responsive: [
    	    {
    	      breakpoint: 1024,
    	      settings: {
    	        slidesToShow: 6,
    	        slidesToScroll: 6,
    	      }
    	    },
    	    {
    	      breakpoint: 600,
    	      settings: {
    	        slidesToShow: 4,
    	        slidesToScroll: 4
    	      }
    	    },
    	    {
    	      breakpoint: 480,
    	      settings: {
    	        slidesToShow: 2,
    	        slidesToScroll: 2
    	      }
    	    }
    	    // You can unslick at a given breakpoint now by adding:
    	    // settings: "unslick"
    	    // instead of a settings object
    	  ]
    	});
    
    
    
    var paginate = true;
    if ($("#plantillaEditoraBeneficis table").find('tr').length <= 10) {
        paginate = false;
    }
    var scroll = false;

    if ($("#plantillaEditoraBeneficis table").data('scroll')) {
        scroll = true;
        //$(this).find('th, tr').css('white-space', 'nowrap');
    }
    
    $("#plantillaEditoraBeneficis table").dataTable({
    	ordering: false,
        bPaginate: paginate,
        bInfo: paginate,
        bStateSave: true,
        scrollX: scroll,
        autoWidth: false,
        dom:
            "<'row'<'medium-6 columns'i><'medium-6 columns'f>>" +
            "<'row'<'medium-12 columns'tr>>" +
            "<'row'<'medium-5 columns'l><'medium-7 columns'p>>",
        stateSave: true,
        stateDuration: 60 * 60,
        language: {
            "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
            "zeroRecords": "No s\'ha trobat cap entrada",
            "info": "Mostrant pàgina _PAGE_ de _PAGES_",
            "infoEmpty": "Cap entrada disponible",
            "infoFiltered": "(de _MAX_ entrades totals)",
            "search": "Cerca:",
            "paginate": {
                "first": "Primera",
                "last": "Última",
                "next": '<span class="vh">Següent</span>',
                "previous": '<span class="vh">Anterior</span>'
            }
        },
        initComplete: function () {
            var table = this;
            this.api().columns().every(function (i) {
                var column = this;
                var titol = '';
                if ($(column.header()).data('select-search') == true) {
                    titol = column.header();
                    info = column.data().map(function(obj){
                        try{
                            if (typeof obj == 'string' && $(obj).text()) {
                                return $(obj).text();
                            } else if (typeof obj != 'string' && $(obj).text()) {
                                return $(obj).text();
                            } else {
                                return obj;
                            }
                        }catch(err){
                            return obj;
                        }
                    });

                    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
                    info = info.unique().sort(collator.compare);

                    if (info.length > 1) {
                        var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
                        search.append('<label>' + $(titol).html() + ':</label>');
                        var select = $('<select class="form-control input-sm"><option value=""></option></select>')
                            .appendTo( search )
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    removeAccents($(this).val())
                                );
                                column
                                    .search( val ? '^'+val+'$' : '', true, false )
                                    .draw();
                            } );
                        var selected = false;
                        info.each( function ( d, j ) {
                            var current = column.search();
                            //TODO: quant no hi ha html falla al map
                            //TODO: no manté el select al canviar de pàgina
                            if (d == '') {
                                return;
                            }
                            if (current != '' && current == '^'+d+'$') {
                                select.append( '<option selected value="'+d+'">'+d+'</option>' );
                                selected = true;
                            } else {
                                select.append( '<option value="'+d+'">'+d+'</option>' );
                            }
                        } );
                        if (selected == false) {
                            //si opció seleccionada no està al llistat, desfer cerca
                            column
                                .search('', true, false )
                                .draw();
                        }
                    } else {
                        //si no hi ha valors pel select, desfer cerca
                        column
                            .search('', true, false )
                            .draw();

                    }
                }
            } );

            var sortSelect = this.data('sortselect');
            if (sortSelect && sortSelect.length > 0) {
                var search = this.closest('.dataTables_wrapper').find('.dataTables_filter');
                search.append('<label>Ordenació:</label>');
                var select = $('<select class="form-control input-sm sorter"></select>')
                    .appendTo( search )
                    .on( 'change', function () {
                        var val = $(this).val();
                        val = val.split('_');
                        table.api()
                            .order([val])
                            .draw();
                    } );

                var currentOrder = table.api().order();
                sortSelect.forEach( function ( d, j ) {
                    if (d.column == currentOrder[0][0]) {
                        select.append( '<option selected data-column="'+d.column+'" value="'+d.column+'_'+d.direction+'">'+d.title+'</option>' );
                    } else {
                        select.append( '<option data-column="'+d.column+'" value="'+d.column+'_'+d.direction+'">'+d.title+'</option>' );
                }
                } );
            }
        },
        "drawCallback": function( settings ) {
            var currentOrder = this.api().order();
            var sorter = this.closest('.dataTables_wrapper').find('.sorter');
            if (sorter.length > 0) {
                sorter.find('option[data-column='+currentOrder[0][0]+']').prop('selected', true);
            }
        }
    });    
});